@use "@/styles/mixins/media-queries" as *;
@use "@/styles/placeholders/matchCardLiveIndicators" as *;

.simpleMatchCard {
  --match-card-padding-left: 0.875em;
  --match-card-padding-right: 0.6875em;
  display: flex;
  flex-direction: column;
  position: relative;
}

.simpleMatchCard__infoMessage {
  padding-top: 0.125em;
}

.simpleMatchCard__infoMessage__secondary {
  color: var(--label-text-color);
}

.simpleMatchCard__content {
  display: grid;
  grid-template-columns: 2.19fr 1fr;
  padding: 1.25em 0;
  background: var(--alert-background-color);
  align-items: center;
  word-break: break-word;

  @include tablet-portrait-only {
    grid-template-columns: 1.97fr 1fr;
  }

  @include tablet-landscape-only {
    grid-template-columns: 1.79fr 1fr;
  }

  @include desktop-and-up {
    grid-template-columns: 2.2fr 1fr;
  }
}

.simpleMatchCard__competition + .simpleMatchCard__content {
  margin-top: 1em;
}

.simpleMatchCard__contentWithHighlights {
  @include tablet-portrait-only {
    grid-template-columns: 1.47fr 1fr;
  }

  @include tablet-landscape-only {
    grid-template-columns: 1.34fr 1fr;
  }

  @include desktop-and-up {
    grid-template-columns: 1.96fr 1fr;
  }
}

.simpleMatchCard__teamsContent {
  padding-left: var(--match-card-padding-left);
  padding-right: 0.625em;
  min-width: 0;
  border-inline-end: 1px solid var(--elevated-background-color);
}

.simpleMatchCard__teamContent {
  & + & {
    margin-top: 0.625em;
  }
}

.simpleMatchCard__matchContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 0;
  padding-left: var(--match-card-padding-right);
  padding-right: var(--match-card-padding-right);
  text-align: center;
}

.simpleMatchCard__highlights {
  position: relative;
}

.simpleMatchCard__highlightsImageWrapper {
  width: 5.0625em;

  @include tablet-portrait-and-up {
    width: 6.25em;
  }

  @include desktop-and-up {
    width: 6.5625em;
  }
}

.simpleMatchCard__highlightsImage {
  border-radius: var(--rounded-corner);
}

.simpleMatchCard__highlightsDuration {
  position: absolute;
  right: 0;
  bottom: 0;
}

.simpleMatchCard__live {
  color: var(--brand-magenta);
}

.simpleMatchCard__live__penalties {
  text-transform: uppercase;
}

.simpleMatchCard__warningMessage {
  color: var(--brand-magenta);
}

.simpleMatchCard__preMatch {
  display: flex;
  flex-direction: column;
}

.simpleMatchCard__aggregatedScore {
  --simple-match-card-aggregated-padding-bottom: 0.375rem;
  color: var(--label-text-color);
  padding-bottom: var(--simple-match-card-aggregated-padding-bottom);
}

@include tablet-portrait-and-up {
  .simpleMatchCard__aggregatedScore {
    --simple-match-card-aggregated-padding-bottom: 0.5rem;
  }
}

@include desktop-and-up {
  .simpleMatchCard__aggregatedScore {
    --simple-match-card-aggregated-padding-bottom: 0.625rem;
  }
}

.simpleMatchCard__liveIndicator {
  @extend %match-card-live-indicator;
  position: absolute;
  top: 0.625em;
  right: 0.625em;
}

.simpleMatchCard__footer {
  margin-top: 1px; // sort of transparent border
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75em;
  background: var(--alert-background-color);
  border-bottom-left-radius: var(--rounded-corner);
  border-bottom-right-radius: var(--rounded-corner);
}

.simpleMatchCard__competition {
  --logo-size: 1.25em;
  min-height: var(--logo-size);
  display: inline-grid;
  gap: 0.625em;
  grid-auto-flow: column;
  align-items: center;
}

.simpleMatchCard__competitionLogo {
  width: var(--logo-size);
  height: var(--logo-size);

  @include desktop-and-up {
    --logo-size: 1.5em;
  }
}

.simpleMatchCard__competitionName {
  color: var(--label-text-color);
}
