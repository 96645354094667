@use "@/styles/typography/title-bold" as *;
@use "@/styles/typography/title-medium" as *;
@use "@/styles/mixins/ellipsis" as *;
@use "@/styles/mixins/media-queries" as *;

.simpleMatchCardTeam {
  display: flex;
  align-items: center;
}

.simpleMatchCardTeam__logo {
  --simple-match-card-team-logo-size: 1.5em;
  min-width: var(--simple-match-card-team-logo-size);
  max-width: var(--simple-match-card-team-logo-size);

  @include tablet-portrait-and-up {
    --simple-match-card-team-logo-size: 1.75em;
  }

  @include desktop-and-up {
    --simple-match-card-team-logo-size: 1.875em;
  }
}

.simpleMatchCardTeam__name {
  @include ellipsis;
  @extend .title-8-medium;

  margin-inline-start: 0.75em;
}

.simpleMatchCardTeam__score {
  @extend .title-7-bold;

  margin-inline-start: auto;
  padding-left: 1em;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
}