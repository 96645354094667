@use "@/styles/typography/title-bold" as *;

.durationLabel {
  @extend .title-8-bold;
  display: grid;
  grid-auto-flow: column;
  gap: 0.37rem;
  align-items: center;
  background: var(--black-0);
  color: var(--white);
  border-bottom-right-radius: var(--rounded-corner);
  border-top-left-radius: var(--rounded-corner);
  padding: 0.25rem 0.375rem;
}

.durationLabel__icon {
  display: flex;
  align-self: center;
}

.durationLabel__text {
  color: var(--white);
}