.entityLogo {
  display: inline-grid;
  border: 1px solid var(--divider-color);
  border-radius: 50%;
  filter: drop-shadow(0px 0px 1.25rem rgba(0, 0, 0, 0.05));
  padding: 15%;
  transition: border-color ease-in-out 250ms;
}

.entityLogoImage {
  width: 100%;
  height: 100%;
}

.entityLogoWithHover:hover {
  @media (hover: hover) {
    border-color: var(--label-text-color);
  }
}
