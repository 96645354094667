.container {
  min-width: 5em;
  margin-top: 0.5em;
  padding: 0.3125em;
  display: flex;
  place-items: center;
  place-content: center;
  background-color: var(--elevated-background-color);
  border-radius: var(--rounded-corner);
}

.icon{
  margin-inline-end: 0.5rem;

  [dir="rtl"] & {
    transform: rotate(180deg);
  }
}
