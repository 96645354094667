@use "@/styles/mixins/media-queries" as *;

.of-image {
  display: flex;
  position: relative;

  @include mobile-only {
    padding-bottom: var(--padding-bottom-mobile);
  }

  @include tablet-portrait-only {
    padding-bottom: var(--padding-bottom-tablet-portrait);
  }

  @include tablet-landscape-only {
    padding-bottom: var(--padding-bottom-tablet-landscape);
  }

  @include desktop-and-up {
    padding-bottom: var(--padding-bottom-desktop);
  }
}

.of-image__picture {
  width: 100%;
  height: 100%;
  position: absolute;
}

.of-image__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
