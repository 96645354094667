@use "@/styles/mixins/max-root-width" as *;
@use "@/styles/mixins/media-queries" as *;

.leftAlign {
  margin-left: 0;
}

.container {
  @include limit;
}

@include mobile-only {
  .delimiter {
    display: none;
  }
}

@include tablet-portrait-and-up {
  .container:has(.delimiter) {
    display: grid;
    gap: 1.25em;
    grid-template-columns: minmax(min-content, max-content) minmax(0, 1fr);
    align-items: center;
  }
}
