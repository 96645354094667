%match-card-live-indicator {
  --live-indicator-width-container: 1.5em;
  width: var(--live-indicator-width-container);
  height: 0.125em;
  margin: 0;
  background-color: var(--divider-color);
  border-radius: calc(2 * var(--rounded-corner));
}

%match-card-live-indicator::after {
  content: "";
  display: block;
  border-radius: calc(2 * var(--rounded-corner));
  background-color: var(--brand-magenta);
  --live-indicator-width: 0.375em;
  width: var(--live-indicator-width);
  --live-indicator-amplitude: calc(
          var(--live-indicator-width-container) - var(--live-indicator-width)
  );
  height: 100%;
  animation: live-indicator 2.2s infinite linear;
}

@keyframes live-indicator {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(var(--live-indicator-amplitude));
  }

  100% {
    transform: translateX(0);
  }
}
