@use "@/styles/mixins/media-queries";

.container {
  display: flex;
  gap: 0.625rem;
}

.link {
  display: flex;
}

.logo__wrapper{
  display: flex;
}

.logo {
  --entity-logo-size: 2.625rem;
  width: var(--entity-logo-size);
  height: var(--entity-logo-size);

  @include media-queries.desktop-and-up {
    --entity-logo-size: 3.125rem;
  }
}

.title {
  display: flex;
  flex-direction: column;
  place-content: space-around;
}

.subtitle {
  margin-top: 0.375rem;
  color: var(--label-text-color);
}

.subtitle__withLogo {
  margin-top: 0.125rem;
}
