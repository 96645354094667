@use "@/styles/mixins/media-queries" as *;
@use "@/styles/mixins/max-root-width" as *;

/*
On mobile and tablets this component looks like a regular container with native
horizontal scrolling with pure CSS.
Desktop UX is totally different and looks like carousel with back and forward
buttons.
There is almost no similarity in CSS rules applied, so it is easier to split
into 2 groups of rules based on the screen size instead of overriding styles
in desktop media query.
 */

@include up-to-tablet-landscape {
  .list {
    display: grid;
    --list-gap: var(--min-distance-between-interactive-elements);
    gap: var(--list-gap);
    padding: 1.25rem 0;
    @include limit;
    // fake elements are required to emulate empty spaces around items when you scroll to the edge
    --fake-element-width: calc(var(--root-side-padding) - var(--list-gap));

    grid-template-columns: var(--fake-element-width);
    grid-auto-flow: column;
    grid-auto-columns: calc(
            (100% / var(--hor-scr-container-visible-items-count-mobile)) -
            (2 / var(--hor-scr-container-visible-items-count-mobile)) * var(--list-gap) -
            (2 / var(--hor-scr-container-visible-items-count-mobile)) *
            var(--fake-element-width) -
            (
            (var(--hor-scr-container-visible-items-count-mobile) - 1) /
            var(--hor-scr-container-visible-items-count-mobile)
            ) * var(--list-gap)
    );

    overflow-x: scroll;
    scrollbar-width: none;
    will-change: scroll-position;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (pointer: coarse) {
      overscroll-behavior-x: contain;
      scroll-snap-type: x mandatory;
    }

    &::before,
    &::after {
      content: "";
      width: var(--fake-element-width);
    }

    .listItem {
      scroll-snap-align: center;
    }

    @include tablet-portrait-and-up {
      grid-auto-columns: calc(
              (100% / var(--hor-scr-container-visible-items-count-tablet-portrait)) -
              (2 / var(--hor-scr-container-visible-items-count-tablet-portrait)) * var(--list-gap) -
              (2 / var(--hor-scr-container-visible-items-count-tablet-portrait)) *
              var(--fake-element-width) -
              (
              (var(--hor-scr-container-visible-items-count-tablet-portrait) - 1) /
              var(--hor-scr-container-visible-items-count-tablet-portrait)
              ) * var(--list-gap)
      );

      --list-gap: max(1em, var(--min-distance-between-interactive-elements));
    }

    @include tablet-landscape-and-up {
      grid-auto-columns: calc(
              (100% / var(--hor-scr-container-visible-items-count-tablet-landscape)) -
              (2 / var(--hor-scr-container-visible-items-count-tablet-landscape)) * var(--list-gap) -
              (2 / var(--hor-scr-container-visible-items-count-tablet-landscape)) *
              var(--fake-element-width) -
              (
              (var(--hor-scr-container-visible-items-count-tablet-landscape) - 1) /
              var(--hor-scr-container-visible-items-count-tablet-landscape)
              ) * var(--list-gap)
      );

      --list-gap: max(1.5em, var(--min-distance-between-interactive-elements));
    }
  }

  // applicable only for carousel UX on larger screen
  .arrowButtonLeft,
  .arrowButtonRight,
  .lastItem {
    display: none;
  }
}

@include desktop-and-up {
  .container {
    position: relative;
    --arrow-button-size: 2.625rem;
    --carousel-gap: 1.25rem;
    --border-button: 1px;
    display: grid;
    align-items: center;
    @include limit;
  }

  .visibleChunk {
    overflow: hidden;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(var(--total-items-count), minmax(0, 1fr));
    width: calc(100% * var(--chunks-count));
    padding: 1.25rem 0;
    gap: var(--carousel-gap);
    --translate-x: calc(-100% / var(--chunks-count) * var(--offset));
    transform: translateX(var(--translate-x));
    transition: transform 700ms ease-in-out;
    grid-auto-rows: 1fr;
  }

  .arrowButtonLeft {
    position: absolute;
    left: calc(-1 * (var(--carousel-gap) + calc(var(--arrow-button-size)/2)));
  }

  .arrowButtonRight{
    position: absolute;
    right: calc(-1 * (var(--carousel-gap) + calc(var(--arrow-button-size)/2)));
  }
}
